"use client";
import { Filters } from "@queries/creator/getContentList";
import { cleanEmptyParams } from "@utils/cleanEmptyParams";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export function useFilters<T extends Filters = Filters>() {
  const router = useRouter();
  const search = useSearchParams();
  const path = usePathname();
  const filters = [...search].reduce((acc, [key, value]) => {
    if (acc[key as keyof T]) {
      if (Array.isArray(acc[key as keyof T])) {
        // @ts-expect-error
        acc[key as keyof T] = [...(acc[key as keyof T] as unknown[]), value];
      } else {
        // @ts-expect-error
        acc[key as keyof T] = [acc[key as keyof T], value];
      }
    } else {
      const values = value.split(",");
      // @ts-expect-error
      acc[key as keyof T] = values.length > 1 ? values : value;
    }

    return acc;
  }, {} as T);

  const setFilters = (partialFilters: Partial<T>) => {
    return router.replace(
      `?${new URLSearchParams(cleanEmptyParams(partialFilters) as Record<string, string>)}`,
    );
  };

  const resetFilters = () => router.replace("");

  return { filters, setFilters, resetFilters };
}
