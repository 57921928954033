import SvgIcon, { SvgIconProps } from "./SvgIcon";
import React from "react";

const TeamIcon: React.FC<SvgIconProps> = ({ color = "#000000", ...props }) => {
  return (
    <SvgIcon width={24} height={24} color="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M1.40002 21.5998C1.39996 22.1521 1.84763 22.5998 2.39991 22.5999C2.9522 22.6 3.39996 22.1523 3.40002
        21.6L1.40002 21.5998ZM2.40043 17.9995L3.40043 17.9996L2.40043 17.9995ZM12.6 15.3999C13.1523 15.3999 13.6
        14.9522 13.6 14.3999C13.6 13.8476 13.1523 13.3999 12.6 13.3999V15.3999ZM21.6 16.5999C22.1523 16.5999 22.6
        16.1522 22.6 15.5999C22.6 15.0476 22.1523 14.5999 21.6 14.5999V16.5999ZM16.2 14.5999C15.6477 14.5999 15.2
        15.0476 15.2 15.5999C15.2 16.1522 15.6477 16.5999 16.2 16.5999V14.5999ZM17.9 18.2998C17.9 18.852 18.3477
        19.2998 18.9 19.2998C19.4523 19.2998 19.9 18.852 19.9 18.2998H17.9ZM19.9 12.8998C19.9 12.3475 19.4523 11.8998
        18.9 11.8998C18.3477 11.8998 17.9 12.3475 17.9 12.8998H19.9ZM17.889 1.5276C17.4072 1.25755 16.7978 1.42918
        16.5277 1.91094C16.2577 2.39271 16.4293 3.00217 16.9111 3.27221L17.889 1.5276ZM16.9111 8.72759C16.4293 8.99764
        16.2577 9.6071 16.5277 10.0889C16.7978 10.5706 17.4072 10.7423 17.889 10.4722L16.9111 8.72759ZM13.4 5.9999C13.4
        7.43584 12.236 8.5999 10.8 8.5999V10.5999C13.3405 10.5999 15.4 8.54041 15.4 5.9999H13.4ZM10.8 8.5999C9.36408
        8.5999 8.20002 7.43584 8.20002 5.9999H6.20002C6.20002 8.54041 8.25951 10.5999 10.8 10.5999V8.5999ZM8.20002
        5.9999C8.20002 4.56396 9.36408 3.3999 10.8 3.3999V1.3999C8.25951 1.3999 6.20002 3.45939 6.20002
        5.9999H8.20002ZM10.8 3.3999C12.236 3.3999 13.4 4.56396 13.4 5.9999H15.4C15.4 3.45939 13.3405 1.3999
        10.8 1.3999V3.3999ZM3.40002 21.6L3.40043 17.9996L1.40043 17.9994L1.40002 21.5998L3.40002 21.6ZM6.00043
        13.3999C3.46012 13.3999 1.40071 15.4591 1.40043 17.9994L3.40043 17.9996C3.40059 16.5638 4.5646 15.3999 6.00043
        15.3999V13.3999ZM6.00043 15.3999H12.6V13.3999H6.00043V15.3999ZM21.6 14.5999H18.9V16.5999H21.6V14.5999ZM18.9
        14.5999H16.2V16.5999H18.9V14.5999ZM19.9 18.2998V15.5999H17.9V18.2998H19.9ZM19.9
        15.5999V12.8998H17.9V15.5999H19.9ZM16.9111 3.27221C18.1487 3.96594 18.8 4.98556 18.8 5.9999H20.8C20.8 4.064
        19.5639 2.46642 17.889 1.5276L16.9111 3.27221ZM18.8 5.9999C18.8 7.01425 18.1487 8.03386 16.9111 8.72759L17.889
        10.4722C19.5639 9.53338 20.8 7.9358 20.8 5.9999H18.8Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default TeamIcon;
